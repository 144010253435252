import React, {useRef} from 'react';

import logo from './img/logo.png';

import vacancies from './img/vacancies.png';
import abouts_us from './img/about_us.png';
import contacts from './img/contacts.png';
import mainBlockImage from './img/main-block-image.png';
import moreInfo from './img/more-info.png';
import mobile from './img/mobile.png';
import vk from './img/vk-icon.png';
import telegram from './img/tg-icon.png';
import email from './img/email-icon.png';
import './App.css';
import {Helmet} from 'react-helmet';
import Slider from "./Slider";

// здесь загружаются фотки для вакансий
import Ozon from './img/vacancies-images/ozon.png';
import yandeks from './img/vacancies-images/yandeks.png';
import Burger from './img/vacancies-images/Burger.jpeg';
import ingo from './img/vacancies-images/ingo.png';
import vv from './img/vacancies-images/vv.jpeg';
import yand from './img/vacancies-images/yand.png';
import sbermark from './img/vacancies-images/sbermark.png';
import sam from './img/vacancies-images/sam.png';
import magnit from './img/vacancies-images/magnit.png';
import rost from './img/vacancies-images/rost.png';
import sber from './img/vacancies-images/sber.png';
import mega from './img/vacancies-images/mega.png';
import voxys from './img/vacancies-images/voxys.png';
import tochka from './img/vacancies-images/tochka.png';
import tin from './img/vacancies-images/tin.jpeg';
import cdek from './img/vacancies-images/cdek.jpeg';
import alf from './img/vacancies-images/alf.png';
import bill from './img/vacancies-images/bill.jpeg';
import yandla from './img/vacancies-images/yandla.png';

function App() {
    const sectionRefVacancies = useRef(null);
    const sectionRefAboutUs = useRef(null);
    const sectionRefContacts = useRef(null);

    const scrollToVacancies = () => {
        sectionRefVacancies.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToAboutUs = () => {
        sectionRefAboutUs.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToContacts = () => {
        sectionRefContacts.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="App">
            <Helmet>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap"
                    rel="stylesheet"/>
            </Helmet>
            <header className="header">
                <div className="header-brand">
                    <img src={logo} alt="Логотип"/>
                </div>
                <ul className="header-buttons">
                    <li>
                        <a href="#home">
                            <button onClick={scrollToVacancies} className="header-button">
                                <img src={vacancies} alt="Вакансии"/>
                                <span>Вакансии</span>
                            </button>
                        </a>
                    </li>
                    <li>
                        <a href="#about">
                            <button onClick={scrollToAboutUs} className="header-button">
                                <img src={abouts_us} alt="О нас"/>
                                <span>О нас</span>
                            </button>
                        </a>
                    </li>
                    <li>
                        <a href="#contacts">
                            <button onClick={scrollToContacts} className="header-button">
                                <img src={contacts} alt="Контакты"/>
                                <span>Контакты</span>
                            </button>
                        </a>
                    </li>
                </ul>
            </header>
            <section className="main-block">
                <div class="main-block__image">
                    <img src={mainBlockImage} alt="Вакансии"/>
                </div>
                <div class="main-block__content">
                    <h1>Мы делаем Ваш успех реальностью</h1>
                    <p>Подбор персонала для ведущих корпораций по всей России</p>
                    <button onClick={scrollToVacancies}>Найти новую работу</button>
                </div>
            </section>
            <section className="companies-block">
                <h2>Станьте представителем ведущих компаний</h2>
                <div className="companies-block__content">
                    <Slider></Slider>
                </div>
            </section>
            <section ref={sectionRefVacancies} className="vacancies-block">
                <h2>Вакансии</h2>
                <p>Ниже вы можете ознакомиться с предложениями наших партнеров и записаться на собеседование</p>
                <div className="vacancies-block__offers">

                    <div className="offer">
                        <img className="offer-logo" src={tin} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Удаленный сотрудник-консультант (подработка, свой график)</p>
                            <p className="offer-desc">Решение вопросов юридических лиц, проведение консультаций.
                                Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в должности.</p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 80 000 ₽</p>
                            <a href="https://streamreg.ru/go/6627deffc597608920">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={tin} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Удаленный сотрудник-оператор (работа из дома)</p>
                            <p className="offer-desc">Ведение переговоров с клиентами, продажа продуктов, проведение
                                консультаций. Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в
                                должности.</p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 70 000 ₽</p>
                            <a href="https://streamreg.ru/go/6627deffc597608920">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={tin} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Менеджер по обработке данных (удаленная работа, подработка) </p>
                            <p className="offer-desc">Консультация клиентов на входящих обращениях.
                                Бесплатное обучение. Сумма вознаграждения увеличивается с ростом в должности.</p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 70 000 ₽</p>
                            <a href="https://streamreg.ru/go/6627deffc597608920">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={tin} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Менеджер по работе с клиентами (удаленная работа)</p>
                            <p className="offer-desc">Ведение переговоров с клиентами, проведение консультаций.
                                Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в
                                должности.</p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 74 000 ₽</p>
                            <a href="https://streamreg.ru/go/6627deffc597608920">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={ingo} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Менеджер по продажам страховых услуг Ингосстрах (удаленная
                                работа)</p>
                            <p className="offer-desc">Возможность работать из дома, гибкий график от 2-х дней в неделю.
                                Предоставляется рабочий ноутбук и наставник, ДМС со стоматологией в соцпакете. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price"> до 150 000 ₽</p>
                            <a href="https://tracking.lovko.pro/click?pid=1322&offer_id=70">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={tin} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Удаленный оператор ввода данных (работа из дома, подработка)</p>
                            <p className="offer-desc">Ведение переговоров с клиентами, оперативное решение различных
                                вопросов.
                                Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в должности.</p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 80 000 ₽</p>
                            <a href="https://streamreg.ru/go/6627deffc597608920">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={tin} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Менеджер дистанционной проверки данных (удаленная работа)</p>
                            <p className="offer-desc">Консультация на входящих и исходящих обращениях, ведение
                                переговоров с клиентами.
                                Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в должности. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 80 000 ₽</p>
                            <a href="https://streamreg.ru/go/6627deffc597608920">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={yandeks} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Менеджер по продажам (удаленная работа)</p>
                            <p className="offer-desc">Официальное трудоустройство с возможностью частичной занятости.
                                Оплачиваемое обучение перед стартом (удалённое). Возможность для роста и развития.</p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">от 48 000 ₽</p>
                            <a href="https://trk.ppdu.ru/click/NyK04sPl?erid=2SDnjcGdzVw">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={voxys} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Специалист центра коммуникаций (удаленная работа)</p>
                            <p className="offer-desc">Гибкий график: 5/2, 2/2, 3/3. Подработка от 4 часов, возможность
                                частичной занятости и совмещения с учебой. Выбор проекта: входящие звонки, исходящие
                                звонки. Оплачиваемое
                                обучение с опытными наставниками.</p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 45 000 ₽</p>
                            <a href="https://my.saleads.pro/s/z9yx6?erid=2VtzqvqwVU9">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={tin} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Специалист клиентский поддержки (удаленная работа)</p>
                            <p className="offer-desc">Ведение переговоров с клиентами, проведение консультаций.
                                Быстрый карьерный рост, сумма вознаграждения увеличивается с ростом в
                                должности.</p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 70 000 ₽</p>
                            <a href="https://streamreg.ru/go/6627deffc597608920">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={bill} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Оператор колл-центра (удаленная работа)</p>
                            <p className="offer-desc">Официальное трудоустройство с оплачиваемым обучением.
                              Удобный график, который выбираете под себя. Возможность для роста и развития.</p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">от 40 000 ₽</p>
                            <a href="https://pxl.leads.su/click/fbe148fd7947ff34db6939126502852d?erid=LjN8KVXpJ">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={yandla} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Кладовщик</p>
                            <p className="offer-desc">Официальное оформление, выплаты 2 раза в месяц на карту.
                                Подобор наиболее близкого склада к вашему дому. График работы на выбор, возможность
                                подстроить под себя. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 153 000 ₽</p>
                            <a href="https://trk.ppdu.ru/click/2lASGjNI?erid=2SDnjdu4gFa">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={Ozon} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Работник склада</p>
                            <p className="offer-desc">Трудоустройство по ТК РФ с первого рабочего дня.
                                Сменный график работы, который можете подстроить под себя. Премии, надбавки за смены в
                                ночь. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">от 150 000 ₽</p>
                            <a href="https://tracking.lovko.pro/click?pid=1322&offer_id=22">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={sbermark} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Курьер на личном авто/пеший курьер</p>
                            <p className="offer-desc">Возможность выбора района доставки, свободный график, льготное
                                обслуживание транспорта.
                                Официальное трудоустройство с первого дня.</p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 226 000 ₽</p>
                            <a href="https://trk.ppdu.ru/click/pETkPa2T?erid=2SDnjeL6Zwp">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={yand} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Пеший/вело курьер</p>
                            <p className="offer-desc">Доставка в районе 3 км, гарантированный доход ежедневно.
                                Возможность совмещения с учебой или с работой, гибкий график, свободная подработка. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 3400 ₽ в день</p>
                            <a href="https://my.saleads.pro/s/s8bgr?erid=2VtzqxakCud">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={sam} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Пеший/вело курьер партнёрской доставки</p>
                            <p className="offer-desc">Быстрое подключение и оплачиваемое двухчасовое обучение, гибкие
                                слоты.
                                Гарантированный еженедельный доход, без задержек. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 168 080 ₽</p>
                            <a href="https://tracking.lovko.pro/click?pid=1322&offer_id=90">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={vv} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Автокурьер/велокурьер</p>
                            <p className="offer-desc">Фиксируемый радиус доставки, ежедневные выплаты, никаких задержек.
                                Возможность совмещения с учебой или с работой, гибкий график. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">от 5000 ₽ в день</p>
                            <a href="https://pxl.leads.su/click/ac500673924cacf1f1e0f4a0832de550?erid=LjN8JugST">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={yandla} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Партнер курьерской доставки</p>
                            <p className="offer-desc">Подключение и первый заказ в течение суток. Еженедельные выплаты.
                                Возможность совмещения с учебой или работой, доставка по закрепленному району. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">от 100 000 ₽</p>
                            <a href="https://trk.ppdu.ru/click/B8nicXau?erid=Kra23uVC3">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={vv} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Бариста</p>
                            <p className="offer-desc">Трудоустройство по ТК РФ с первого рабочего дня, обучение.
                                Компенсация за питание, а также медицины и фитнеса 50%. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 78 300 ₽</p>
                            <a href="https://streamreg.ru/go/65d46bf33f8ce08920">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={Burger} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Повар-кассир</p>
                            <p className="offer-desc">График работы на выбор, возможна свободная подработка.
                                Вкусное питание и красивая форма от компании, обучение. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 96 000 ₽</p>
                            <a href="https://streamreg.ru/go/665dc82b3d04208920">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={vv} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Повар</p>
                            <p className="offer-desc">Трудоустройство по ТК РФ с первого рабочего дня, обучение.
                                Компенсация за питание, а также медицины и фитнеса 50%. </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">до 75 000 ₽</p>
                            <a href="https://streamreg.ru/go/65d46bf33f8ce08920">
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="offer">
                        <img className="offer-logo" src={Ozon} alt="Логотип"/>
                        <div className="offer-text">
                            <p className="offer-name">Курьер на личном авто и авто компании</p>
                            <p className="offer-desc">Личное авто / Автомобиль компании.
                                Выберите тип авто и ближайший склад — получайте от 77 000 до 250 000 ₽ в месяц </p>
                        </div>
                        <div className="offer-content">
                            <p className="offer-price">от 77 000 ₽</p>
                            <a href={"https://tracking.lovko.pro/click?pid=1322&offer_id=82"}>
                                <button className="offer-button">
                                    <span>Подробнее о вакансии</span>
                                    <img src={moreInfo} alt="Подробнее о вакансии"/>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section ref={sectionRefAboutUs} className="about-block">
                <h2> Poiskwork.ru</h2>
                <p>Наша компания осуществляет подбор персонала напрямую от заказчиков.<br></br>
                    Мы работаем над тем, чтобы Вы нашли <strong>работу своей мечты</strong>, <br></br>
                    а работодатели - <strong>лучших сотруников</strong>. <br></br>
                    Только проверенные и актуальные предложения о трудоустройстве.</p>
            </section>
            <section ref={sectionRefContacts} className="contacts-block">
                <div className="contacts-block__content">
                    <div className="info-text">
                        <h2>Подпишитесь на нас <br></br> в социальных сетях</h2>
                        <p>Там мы делимся информацией об <br></br>актуальных вакансиях и бирже труда в целом</p>

                        <div className="social-buttons">
                            <a href="https://vk.com/poiskvvork" target="_blank" rel="noopener noreferrer">
                                <button className="btn-vk">
                                <img src={vk} alt="ВКонтакте"/>
                                    <span>Подписаться в ВК</span>
                                </button>
                            </a>
                            <a href="https://t.me/poiskvvork" target="_blank" rel="noopener noreferrer">
                                <button className="btn-tg">
                                    <img src={telegram} alt="Telegram"/>
                                    <span>Подписаться в Telegram</span>
                                </button>
                            </a>
                            <a href="mailto:19amakarov96@bk.ru">
                                <button className="btn-email">
                                    <img src={email} alt="Электронная почта"/>
                                    <span>Связаться с нами по электронной почте</span>
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="mobile-preview">
                        <img src={mobile} alt="Mobile Preview"/>
                    </div>
                </div>
            </section>
            <footer>
                <p>© 2024 «Poiskwork». Все права защищены.</p>
            </footer>
        </div>
    );
};

export default App;
